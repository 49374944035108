import { createContext } from 'react';
import { useLocalStorage } from "../hooks/useLocalStorage";

export const ColorModeContext = createContext<ColorMode|undefined>(undefined);

function NewColorMode(): ColorMode {
  const [colorModeState, setColorModeState] = useLocalStorage(
    "colour_mode",
    "light"
  );

  const cm = new ColorMode(
    colorModeState,
    setColorModeState,
  );

  return cm
}

class ColorMode {
  private colorMode: string;
  private colorModeSetter: (value: string | ((val: string) => string)) => void;

  constructor(
    colorMode: string,
    colorModeSetter: (value: string | ((val: string) => string)) => void
  ) {
    this.colorMode = colorMode;
    this.colorModeSetter = colorModeSetter;
  }

  public toggle() {
    if (this.colorMode === "dark") {
      console.log("toggling color to light")
      this.colorModeSetter("light");
    } else {
      console.log("toggling color to dark")
      this.colorModeSetter("dark");
    }
  }

  public set(newColorMode: string) {
    this.colorModeSetter(newColorMode);
  }

  public get(): string {
    return this.colorMode;
  }
}

export {NewColorMode, ColorMode}
