import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./Navbar";
import LinearProgress from "@mui/material/LinearProgress";
import {
  useSnackbar,
} from "notistack";

import { APIContext } from "../contexts/APIContext";
import { UserContext } from "../contexts/UserContext";


function Base() {
  const { enqueueSnackbar } = useSnackbar();

  const api = useContext(APIContext);
  const user = useContext(UserContext);

  api?.addAPISuccessCallback((response: Response) => {
    if (response.url.includes("/oauth2/callback")) {
      if (response.status === 200) {
        enqueueSnackbar("Logged in", { variant: "success" });
      }
    }
  });

  api?.addAPIErrorCallback((err: Response) => {
    if (err.status === 401) {
      user?.logout();
      enqueueSnackbar("Logged out", { variant: "error" });
    }

    if (err.status >= 500) {
      enqueueSnackbar(err.statusText, { variant: "error" });
    }
  });

  if (user?.isAuthenticating()) {
    return <LinearProgress />;
  }

  return (
    <>
      <NavBar />
      <Outlet />
    </>
  );
}
export default Base;
