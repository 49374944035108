import React, { useContext, useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import { styled, alpha } from "@mui/material/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { V1Alpha1TenantState } from "../api/api";
import HomeIcon from "@mui/icons-material/Home";

import { UserContext } from "../contexts/UserContext";
import { TenantsContext } from "../contexts/TenantsContext";
import { useNavigate, useLocation } from "react-router-dom";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  color: "inherit",
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(Select)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "20ch",
      "&:focus": {
        width: "24ch",
      },
    },
  },
}));


export default function TenantSelector() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useContext(UserContext);
  const tenants = useContext(TenantsContext);

  const undefinedTenantValue = "Select a tenant..."

  useEffect(() => {
    if (user?.isAuthenticated()) {
      tenants?.fetchTenants()
    }
  }, []);

  if (location.pathname == "/" || !user?.isAuthenticated()) {
    return (<></>)
  }

  return (
    <>
      <FormControl sx={{ color: "inherit" }}>
        <Search>
          <SearchIconWrapper>
            <HomeIcon />
          </SearchIconWrapper>
          <StyledInputBase
            sx={{ color: "inherit" }}
            id="tenant"
            displayEmpty={true}
            onChange={(event: SelectChangeEvent<unknown>) => {
              if (event.target.value === undefinedTenantValue) {
                console.log("unset current tenant")
                tenants?.setCurrentTenant(undefined);
                navigate("/tenants");
                return
              }

              const newTenant = tenants?.getTenantByName(
                event.target.value as string
              );
              tenants?.setCurrentTenant(newTenant);
              console.log(`set current tenant: ${newTenant?.name}`);
              if (newTenant !== undefined) {
                navigate(`/tenants/${newTenant?.name}`);
              }
            }}
            value={tenants?.getCurrentTenantName() || undefinedTenantValue}
            renderValue={() => {
              const current = tenants?.getCurrentTenant();
              if (current !== undefined) {
                return tenants?.tenantFriendlyName(current);
              }
              return "Select a tenant...";
            }}
          >
            <MenuItem
                sx={{ color: "inherit" }}
                key={undefinedTenantValue}
                value={undefinedTenantValue}
              >
                Select a tenant...
              </MenuItem>
            {tenants?.getTenants().map((tenant) => (

              <MenuItem
                sx={{ color: "inherit" }}
                key={tenant.name}
                value={tenant.name}
                disabled={
                  tenant.state !== V1Alpha1TenantState.TENANT_STATE_ACTIVE
                }
              >
                {tenants?.tenantFriendlyName(tenant)}{" "}
                {tenant.state !== V1Alpha1TenantState.TENANT_STATE_ACTIVE
                  ? "(inactive)"
                  : ""}
              </MenuItem>
            ))}
          </StyledInputBase>
        </Search>
      </FormControl>
    </>
  );
}
