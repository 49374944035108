import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import Logo from "../components/Logo";
import Settings from "../components/Settings";
import TenantSelector from "../components/TenantSelector";

export default function NavBar() {
  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Logo />
            <Box
              sx={{ flexGrow: 1, justifyContent: "center", display: "flex" }}
            >
              <TenantSelector />
            </Box>
            <Settings />
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}
