import React, { useContext, useEffect } from "react";

import { V1Alpha1Tenant } from "../api/api";
import { useParams, useNavigate } from "react-router-dom";
import {TenantsContext} from "../contexts/TenantsContext";

function TenantDashboard() {
  const tenants = useContext(TenantsContext);
  const { tenantId } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    if (tenantId !== undefined) {
      const routedTenant = tenants?.getTenantByName(tenantId);
      if (routedTenant !== undefined) {
        tenants?.setCurrentTenant(routedTenant);
        console.log(`set current tenant as '${routedTenant.name}' from routing`)
      } else if (tenants !== undefined && tenants.getTenants().length > 0) {
        console.log(`routing to /tenants as ${tenantId} does not exist for user`)
        navigate("/tenants")
      }
    }
  }, [tenants?.getTenants()])

  return (
    <>
      Dashboard for:{" "}
      {tenants?.tenantFriendlyName(
        tenants?.getCurrentTenant() as V1Alpha1Tenant
      )}
    </>
  );
}

export default TenantDashboard;
