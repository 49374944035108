/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ProtobufAny {
  "@type"?: string;
  [key: string]: any;
}

export interface RpcStatus {
  /** @format int32 */
  code?: number;
  message?: string;
  details?: ProtobufAny[];
}

/** AccessTokenClaims represents the claims that are set upon an access token. */
export interface V1Alpha1AccessTokenClaims {
  /** user is the User that the token identifies. */
  user?: V1Alpha1User;
  /**
   * o_auth_2_review_url is the URL which users can visit to review their OAuth2
   * access and settings for this application.
   */
  oAuth2ReviewUrl?: string;
}

/**
 * CallbackResponse represents the response returned when processing an OAuth2
 * callback.
 */
export interface V1Alpha1CallbackResponse {
  /**
   * The Access Token with the claims encoded and signed as a JWT. This should
   * be re-used to interact with this API.
   * Client's should keep it secret, keep it safe.
   */
  accessToken?: string;
  /**
   * claims are the claims that this access token has attached to it. This
   * saves the client from decoding the token but should not be relied upon.
   */
  claims?: V1Alpha1AccessTokenClaims;
}

/**
 * ComponentStage represents the available Pipeline stages of Dracon components.
 *
 *  - COMPONENT_STAGE_UNSPECIFIED: COMPONENT_STAGE_UNSPECIFIED is the default-zero value which is unsupported
 * and should result in an error.
 *  - COMPONENT_STAGE_SOURCE: COMPONENT_STAGE_SOURCE represents components that are for the Source stage.
 *  - COMPONENT_STAGE_PRODUCER: COMPONENT_STAGE_PRODUCER represents components that are for the Producer stage.
 *  - COMPONENT_STAGE_ENRICHER: COMPONENT_STAGE_ENRICHER represents components that are for the Enricher stage.
 *  - COMPONENT_STAGE_CONSUMER: COMPONENT_STAGE_CONSUMER represents components that are for the Consumer stage.
 * @default "COMPONENT_STAGE_UNSPECIFIED"
 */
export enum V1Alpha1ComponentStage {
  COMPONENT_STAGE_UNSPECIFIED = "COMPONENT_STAGE_UNSPECIFIED",
  COMPONENT_STAGE_SOURCE = "COMPONENT_STAGE_SOURCE",
  COMPONENT_STAGE_PRODUCER = "COMPONENT_STAGE_PRODUCER",
  COMPONENT_STAGE_ENRICHER = "COMPONENT_STAGE_ENRICHER",
  COMPONENT_STAGE_CONSUMER = "COMPONENT_STAGE_CONSUMER",
}

/**
 * CreatePipelineResponse represents the returned values for creating a
 * pipeline.
 */
export interface V1Alpha1CreatePipelineResponse {
  /** pipeline is the pipeline that was created. */
  pipeline?: V1Alpha1Pipeline;
}

/** CreatePipelineRunResponse represents the returned values for running a pipeline. */
export interface V1Alpha1CreatePipelineRunResponse {
  /** pipeline_run is the pipeline run that was created. */
  pipelineRun?: V1Alpha1PipelineRun;
}

/**
 * DeletePipelineResponse represents the returned values when deleting a
 * pipeline.
 */
export type V1Alpha1DeletePipelineResponse = object;

/**
 * CancelPipelineRunResponse represents the returned values for stopping a
 * pipeline run execution.
 */
export type V1Alpha1DeletePipelineRunResponse = object;

/**
 * EndpointRequest represents the request parameters for fetching an OAuth2
 * endpoint URL.
 */
export interface V1Alpha1EndpointRequest {
  /** provider_id is the OAuth2 provider id (a.k.a prefix) to use for SSO. */
  providerId?: string;
}

/**
 * EndpointResponse represents the response returned when fetching an OAuth2
 * endpoint URL.
 */
export interface V1Alpha1EndpointResponse {
  /** endpoint is the URL to redirect the client to. */
  endpoint?: string;
  /** request is the original request that a client can use to verify. */
  request?: V1Alpha1EndpointRequest;
}

/**
 * Event represents an event against a Dracon resource. These may be displayed to
 * end users to help them understand what is happening with their resources from a
 * high level.
 */
export interface V1Alpha1Event {
  /**
   * timestamp is the time that the event occured.
   * @format date-time
   */
  timestamp?: string;
  /** message is the event that happened. */
  message?: string;
}

/** GCPPubSubMessage represents the message part of a pushed GCP Pub/Sub message. */
export interface V1Alpha1GCPPubSubMessage {
  /** attributes are the attributes of a GCP Pub/Sub message. */
  attributes?: Record<string, string>;
  /** data is the payload of the message. */
  data?: string;
  /** message_id is the ID of the message in Pub/Sub. */
  messageId?: string;
  /**
   * publish_time is the time that the message was published.
   * @format date-time
   */
  publishTime?: string;
}

/**
 * GCPPubSubPushRequest represents the parameters pushed by GCP Pub/Sub. This is
 * based off of the structure here:
 * https://cloud.google.com/pubsub/docs/push#receive_push.
 * ```json
 * {
 *   "message": {
 *       "attributes": {
 *           "key": "value"
 *       },
 *       "data": "SGVsbG8gQ2xvdWQgUHViL1N1YiEgSGVyZSBpcyBteSBtZXNzYWdlIQ==",
 *       "messageId": "2070443601311540",
 *       "message_id": "2070443601311540",
 *       "publishTime": "2021-02-26T19:13:55.749Z",
 *       "publish_time": "2021-02-26T19:13:55.749Z"
 *   },
 *  "subscription": "projects/myproject/subscriptions/mysubscription"
 * }
 * ```
 */
export interface V1Alpha1GCPPubSubPushRequest {
  /** message is the GCP Pub/Sub message. */
  message?: V1Alpha1GCPPubSubMessage;
  /** subscription is the subscription that the message came via. */
  subscription?: string;
}

/**
 * GCPPubSubPushResponse represents the return values for pushing a message.
 * The status code returned follows the documentation:
 * https://cloud.google.com/pubsub/docs/push#receive_push.
 * Acknowledged status codes:
 * - 102
 * - 200
 * - 201
 * - 202
 * - 204
 */
export type V1Alpha1GCPPubSubPushResponse = object;

/**
 * GenerateOptionsResponse represents the response values for getting generator
 * options.
 */
export interface V1Alpha1GenerateOptionsResponse {
  /**
   * kustomizeable_pipeline represents the available kustomizeable pipeline
   * options to be used with the generator.
   */
  kustomizeablePipeline?: V1Alpha1KustomizeablePipelineOptions;
}

/** GenerateRequest represents the request parameters for generating a pipeline. */
export interface V1Alpha1GenerateRequest {
  /** pipeline is the pipeline to fulfil generation for. */
  pipeline?: V1Alpha1Pipeline;
}

/**
 * GeneratorConfiguration represents the available methods of generating Dracon
 * Pipelines and their configuration options.
 */
export interface V1Alpha1GeneratorConfiguration {
  /**
   * annotations represent additional metadata that may be added by generators.
   * This is useful if generators are re-using other generators in a "suggestion"
   * process.
   */
  annotations?: Record<string, string>;
  /**
   * kustomize is the original Kustomize pipeline configuration
   * that the resource was generated from. This should be used for UI, or even
   * regenerating pipelines from configuration.
   */
  kustomize?: V1Alpha1KustomizeablePipeline;
  /**
   * repository_source represents a repository URI to download and generate a
   * pipeline based upon its source code.
   */
  repositorySource?: V1Alpha1RepositorySourcePipeline;
  /**
   * package_url represents a repository from its PURL to download and
   * generate pipeline based upon its source code.
   */
  packageUrl?: V1Alpha1PackageURLPipeline;
}

/** GetPipelineResponse represents the returned values for getting a pipeline. */
export interface V1Alpha1GetPipelineResponse {
  /** pipeline is the pipeline which matched the given criteria. */
  pipeline?: V1Alpha1Pipeline;
}

/**
 * GetPipelineRunResponse represents the returned values for getting a pipeline
 * run.
 */
export interface V1Alpha1GetPipelineRunResponse {
  /** pipeline_run is the pipeline run which met the given criteria. */
  pipelineRun?: V1Alpha1PipelineRun;
}

/** GetTenantResponse represents the returned tenant. */
export interface V1Alpha1GetTenantResponse {
  /** tenant is the returned tenant. */
  tenant?: V1Alpha1Tenant;
}

/**
 * KustomizePipelineComponent represents a single Kustomize component that is
 * unique by its name. The Tekton Task parameters are replaced with by the
 * name, and their defaults replaced by the given defaults.
 */
export interface V1Alpha1KustomizePipelineComponent {
  /** name is the unique name of the component. */
  name?: string;
  /** id is the id of the component. */
  id?: string;
  /**
   * new_parameter_defaults are the new parameter defaults to set within the
   * Component's Tasks.
   */
  newParameterDefaults?: V1Alpha1PipelineParameter[];
}

/**
 * KustomizePipelineOptionComponent represents an available kustomize component
 * to use as an option for the generator.
 */
export interface V1Alpha1KustomizePipelineOptionComponent {
  /** id is a unique id of the component. */
  id?: string;
  /** stage is the Dracon pipeline stage of the available component. */
  stage?: V1Alpha1ComponentStage;
  /** name is the human readable name of the component. */
  name?: string;
  /**
   * parameters are the existing parameter defaults to set within the
   * Component's Tasks.
   */
  parameters?: V1Alpha1PipelineParameter[];
}

/**
 * KustomizeablePipeline represents a Dracon Pipeline which uses Kustomize to
 * generate the Tekton Pipeline resources.
 */
export interface V1Alpha1KustomizeablePipeline {
  /**
   * dracon_components is a list of Pipeline components. These get downloaded
   * into paths by their name, and their parameters modified, before being
   * passed to Kustomize so that we can re-use components multiple times.
   */
  draconComponents?: V1Alpha1KustomizePipelineComponent[];
}

/** KustomizeablePipelineOptions */
export interface V1Alpha1KustomizeablePipelineOptions {
  /** components is a list of components which can be used for generating. */
  components?: V1Alpha1KustomizePipelineOptionComponent[];
}

/**
 * ListCreatePipelineOptionsResponse represents the returned values for listing
 * create pipeline options.
 */
export interface V1Alpha1ListCreatePipelineOptionsResponse {
  /**
   * generate_options are the available generate options for creating a
   * pipeline.
   */
  generateOptions?: V1Alpha1GenerateOptionsResponse;
}

/**
 * ListPipelineRunsResponse represents the returned values for listing pipeline
 * runs.
 */
export interface V1Alpha1ListPipelineRunsResponse {
  /** pipeline_runs are the pipeline runs which matched the request criteria. */
  pipelineRuns?: V1Alpha1PipelineRun[];
}

/** ListPipelinesResponse represents the return values for listing pipelines. */
export interface V1Alpha1ListPipelinesResponse {
  /** pipelines are the pipelines which matched the given criteria. */
  pipelines?: V1Alpha1Pipeline[];
}

/** ListTenantsResponse represents the response returned for listing tenants. */
export interface V1Alpha1ListTenantsResponse {
  /** tenants is the list of tenants that are returned. */
  tenants?: V1Alpha1Tenant[];
}

/**
 * PackageURLPipeline represents a Dracon Pipeline which uses a PURL source to
 * generate a Kustomizeable pipeline.
 */
export interface V1Alpha1PackageURLPipeline {
  /** package_url is the PURL to determine a source and generate a pipeline for. */
  packageUrl?: string;
}

/**
 * Pipeline represents a Dracon pipeline. We're using existing Kubernetes CRDs
 * define our pipelines so this is ust a wrapper to store their rendered output.
 */
export interface V1Alpha1Pipeline {
  /** id is the fully qualified identifier for the pipeline. */
  id?: string;
  /** name is the name of the pipeline that tenants see. */
  name?: string;
  /** tenant is the tenant which owns the pipeline. */
  tenant?: V1Alpha1Tenant;
  /** state is the current state of the pipeline. */
  state?: V1Alpha1PipelineState;
  /**
   * created_at is when the pipeline was created.
   * @format date-time
   */
  createdAt?: string;
  /**
   * updated_at is when the pipelinet was updated.
   * @format date-time
   */
  updatedAt?: string;
  /** resource_yaml is kubernetes yaml Pipeline resource. */
  resourceYaml?: string;
  /**
   * available_parameters is the available parameters for the pipeline and their
   * defaults which may be overridden when creating a pipelinerun. These are
   * reflected from the resource_yaml. This should never be updated directly and
   * is purely used as a helper for clients to quickly determine what parameters
   * are available for the Pipeline when creating a PipelineRun.
   */
  availableParameters?: V1Alpha1PipelineParameter[];
  /**
   * events are used to keep a log/record of events with the resource. These are
   * useful for debugging asynchronous actions.
   */
  events?: V1Alpha1Event[];
  /** generator_configuration is the configuration for generating the pipeline. */
  generatorConfiguration?: V1Alpha1GeneratorConfiguration;
}

/** PipelineParameter represents a parameter for a Pipeline. */
export interface V1Alpha1PipelineParameter {
  /** name is the name of the Parameter within the Tekton Task. */
  name?: string;
  /** description is user-facing information about what the parameter does. */
  description?: string;
  /** string is the string value of the default value. */
  string?: string;
  /** array is the array value of the default value. */
  array?: V1Alpha1StringArray;
}

/**
 * PipelineRun represents a PipelineRun Dracon resource which persists forever.
 * This is used to generate a single PipelineRunRun execution.
 */
export interface V1Alpha1PipelineRun {
  /** id is the fully qualified identifier for the pipeline run. */
  id?: string;
  /** pipeline is the Pipeline that the PipelineRun is associated with. */
  pipeline?: V1Alpha1Pipeline;
  /** k8s_yaml is the kubernetes representation of the PipelineRun. */
  k8sYaml?: string;
  /**
   * parameters are the pipeline run parameters which are used to override a
   * pipeline's parameters. These are reflected from the k8s_yaml.
   */
  parameters?: V1Alpha1PipelineRunParameter[];
  /** state is the current state of the pipeline run. */
  state?: V1Alpha1PipelineRunState;
  /**
   * created_at is when the pipeline was created.
   * @format date-time
   */
  createdAt?: string;
  /**
   * updated_at is when the pipelinet was updated.
   * @format date-time
   */
  updatedAt?: string;
  /**
   * events are used to keep a log/record of events with the resource. These are
   * based upon the events on the k8s_yaml. These are useful for debugging
   * asynchronous actions.
   */
  events?: string[];
}

/**
 * PipelineRunParameter represents a parameter for a pipeline run which
 * overrides the pipeline parameters.
 */
export interface V1Alpha1PipelineRunParameter {
  /** name is the name of the parameter. */
  name?: string;
  /** string is a string value. */
  string?: string;
  /** array is an array value. */
  array?: V1Alpha1StringArray;
}

/**
 * PipelineRunState represents the possible states a Pipeline Run may be in.
 *
 *  - PIPELINE_RUN_STATE_UNSPECIFIED: PIPELINE_RUN_STATE_UNSPECIFIED is an invalid pipeline run state.
 *  - PIPELINE_RUN_STATE_PROCESSING: PIPELINE_RUN_STATE_PROCESSING indicates that the pipeline run is being
 * processed by the system and it will be updated.
 *  - PIPELINE_RUN_STATE_PROCESSED: PIPELINE_RUN_STATE_PROCESSED indicates that the pipeline run has no pending
 * changes.
 *  - PIPELINE_RUN_STATE_ERROR: PIPELINE_RUN_STATE_ERROR indicates that an error was encountered whilst
 * processing the pipeline run.
 * @default "PIPELINE_RUN_STATE_UNSPECIFIED"
 */
export enum V1Alpha1PipelineRunState {
  PIPELINE_RUN_STATE_UNSPECIFIED = "PIPELINE_RUN_STATE_UNSPECIFIED",
  PIPELINE_RUN_STATE_PROCESSING = "PIPELINE_RUN_STATE_PROCESSING",
  PIPELINE_RUN_STATE_PROCESSED = "PIPELINE_RUN_STATE_PROCESSED",
  PIPELINE_RUN_STATE_ERROR = "PIPELINE_RUN_STATE_ERROR",
}

/**
 * PipelineState represents the possible states a Pipeline may be in.
 *
 *  - PIPELINE_STATE_UNSPECIFIED: PIPELINE_STATE_UNSPECIFIED is an invalid pipeline state.
 *  - PIPELINE_STATE_PROCESSING: PIPELINE_STATE_PROCESSING indicates that the pipeline is being processed
 * by the system and it will be updated.
 *  - PIPELINE_STATE_PROCESSED: PIPELINE_STATE_PROCESSED indicates that the pipeline has no pending
 * changes.
 *  - PIPELINE_STATE_ERROR: PIPELINE_STATE_ERROR indicates that an error was encountered whilst
 * processing the pipeline.
 * @default "PIPELINE_STATE_UNSPECIFIED"
 */
export enum V1Alpha1PipelineState {
  PIPELINE_STATE_UNSPECIFIED = "PIPELINE_STATE_UNSPECIFIED",
  PIPELINE_STATE_PROCESSING = "PIPELINE_STATE_PROCESSING",
  PIPELINE_STATE_PROCESSED = "PIPELINE_STATE_PROCESSED",
  PIPELINE_STATE_ERROR = "PIPELINE_STATE_ERROR",
}

/**
 * ProvidersResponse represents the response for listing the available OAuth2
 * providers.
 */
export interface V1Alpha1ProvidersResponse {
  /**
   * provider_ids is the list of provider ids (a.k.a prefixes) that are
   * available.
   */
  providerIds?: string[];
}

/**
 * RepositorySourcePipeline represents a Dracon Pipeline which uses a repository
 * source to generate a Kustomizeable pipeline.
 */
export interface V1Alpha1RepositorySourcePipeline {
  /** git_url is the git compatible url to clone and generate a pipeline for. */
  gitUrl?: string;
}

/** StringArray wraps the array type so it can be used with `oneof`. */
export interface V1Alpha1StringArray {
  /** array is the underlying string array value. */
  array?: string[];
}

/** Tenant represents a Tenant in a multi-tenanted system. */
export interface V1Alpha1Tenant {
  /** name is the unique name of the Tenant. */
  name?: string;
  /** state is the current state that the Tenant is in. */
  state?: V1Alpha1TenantState;
}

/**
 * TenantState represents the possible states of a Tenant.
 *
 *  - TENANT_STATE_UNSPECIFIED: TENANT_STATE_UNSPECIFIED represents the default unspecified state of a
 * tenant.
 *  - TENANT_STATE_INACTIVE: TENANT_STATE_INACTIVE represents the state in which a Tenant is inactive.
 *  - TENANT_STATE_ACTIVE: TENANT_STATE_ACTIVE represents the state in which a Tenant is active.
 * @default "TENANT_STATE_UNSPECIFIED"
 */
export enum V1Alpha1TenantState {
  TENANT_STATE_UNSPECIFIED = "TENANT_STATE_UNSPECIFIED",
  TENANT_STATE_INACTIVE = "TENANT_STATE_INACTIVE",
  TENANT_STATE_ACTIVE = "TENANT_STATE_ACTIVE",
}

/**
 * UpdatePipelineResponse represents the returned values when updating a
 * pipeline.
 */
export interface V1Alpha1UpdatePipelineResponse {
  /** pipeline is the pipeline that was updated. */
  pipeline?: V1Alpha1Pipeline;
}

/** User represents a multi-tenanted User in the system. */
export interface V1Alpha1User {
  /** tenants is a list of all the tenants that the user can access. */
  tenants?: V1Alpha1Tenant[];
  /** username is the unique username for the User. */
  username?: string;
}

/** VerifyResponse returns the claims that are associated with the access token. */
export interface V1Alpha1VerifyResponse {
  /** claims are the claims associated with the access token. */
  claims?: V1Alpha1AccessTokenClaims;
}

export namespace Oauth2 {
  /**
 * No description
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceCallback
 * @summary Callback is where users get redirected to after they are authenticated
with the authorization server.
 * @request GET:/oauth2/callback
 * @response `200` `V1Alpha1CallbackResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace OAuth2ClientServiceCallback {
    export type RequestParams = {};
    export type RequestQuery = {
      /** The server returns the authorization code in the query string. */
      code?: string;
      /** The server returns the same state value that you passed. */
      state?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1CallbackResponse;
  } /**
 * No description
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceEndpoint
 * @summary Endpoint redirects/returns OAuth2 `/authorize` URL for clients to visit
in order to gain authentication.
 * @request GET:/oauth2/endpoint
 * @response `200` `V1Alpha1EndpointResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace OAuth2ClientServiceEndpoint {
    export type RequestParams = {};
    export type RequestQuery = {
      /** provider_id is the OAuth2 provider id (a.k.a prefix) to use for SSO. */
      providerId?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1EndpointResponse;
  } /**
 * No description
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceProviders
 * @summary Providers returns a list of configured OAuth2 providers for clients to
present as authentication options.
 * @request GET:/oauth2/providers
 * @response `200` `V1Alpha1ProvidersResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace OAuth2ClientServiceProviders {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1ProvidersResponse;
  } /**
 * No description
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceVerify
 * @summary Verify verifies the given access token from metadata, returning the
authenticated claims for the token.
 * @request GET:/oauth2/verify
 * @secure
 * @response `200` `V1Alpha1VerifyResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace OAuth2ClientServiceVerify {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1VerifyResponse;
  }
}

export namespace V1Alpha1 {
  /**
 * No description
 * @tags PipelinesService
 * @name PipelinesServiceListCreatePipelineOptions
 * @summary ListCreatePipelineOptions returns a list of available Create Pipeline Options. For
example a list of available kustomize components.
 * @request GET:/v1alpha1/create-pipeline-options
 * @secure
 * @response `200` `V1Alpha1ListCreatePipelineOptionsResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace PipelinesServiceListCreatePipelineOptions {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1ListCreatePipelineOptionsResponse;
  }
  /**
   * No description
   * @tags TenantsService
   * @name TenantsServiceListTenants
   * @summary ListTenants returns a list of tenant that satisfies the given request.
   * @request GET:/v1alpha1/tenants
   * @secure
   * @response `200` `V1Alpha1ListTenantsResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace TenantsServiceListTenants {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1ListTenantsResponse;
  }
  /**
   * No description
   * @tags TenantsService
   * @name TenantsServiceGetTenant
   * @summary GetTenant returns a tenant for the given unique name.
   * @request GET:/v1alpha1/tenants/{name}
   * @secure
   * @response `200` `V1Alpha1GetTenantResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace TenantsServiceGetTenant {
    export type RequestParams = {
      /** name is the unique name of the tenant. */
      name: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1GetTenantResponse;
  }
  /**
   * No description
   * @tags PipelinesService
   * @name PipelinesServiceListPipelines
   * @summary ListPipelines returns a list of pipelines for the given tenant.
   * @request GET:/v1alpha1/tenants/{tenant}/pipelines
   * @secure
   * @response `200` `V1Alpha1ListPipelinesResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace PipelinesServiceListPipelines {
    export type RequestParams = {
      /** tenant is the tenant to list pipelines for. */
      tenant: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1ListPipelinesResponse;
  } /**
 * No description
 * @tags PipelinesService
 * @name PipelinesServiceCreatePipeline
 * @summary CreatePipeline creates a new pipeline with the given parameters for the
given tenant.
 * @request POST:/v1alpha1/tenants/{tenant}/pipelines
 * @secure
 * @response `200` `V1Alpha1CreatePipelineResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace PipelinesServiceCreatePipeline {
    export type RequestParams = {
      /** tenant is the tenant to create a pipeline for. */
      tenant: string;
    };
    export type RequestQuery = {};
    export type RequestBody = {
      /** name is the name of the pipeline to create. */
      name?: string;
      /** generator_configuration is the parameters for generating a pipeline. */
      generatorConfiguration?: V1Alpha1GeneratorConfiguration;
    };
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1CreatePipelineResponse;
  }
  /**
   * No description
   * @tags PipelinesService
   * @name PipelinesServiceGetPipeline
   * @summary GetPipeline returns the given Pipeline by its tenant and id.
   * @request GET:/v1alpha1/tenants/{tenant}/pipelines/{id}
   * @secure
   * @response `200` `V1Alpha1GetPipelineResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace PipelinesServiceGetPipeline {
    export type RequestParams = {
      /** tenant is the tenant to retrieve a pipeline for. */
      tenant: string;
      /** id is the id of the pipeline to get. */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1GetPipelineResponse;
  }
  /**
   * No description
   * @tags PipelinesService
   * @name PipelinesServiceDeletePipeline
   * @summary DeletePipeline deletes a given pipeline for the given tenant.
   * @request DELETE:/v1alpha1/tenants/{tenant}/pipelines/{id}
   * @secure
   * @response `200` `V1Alpha1DeletePipelineResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace PipelinesServiceDeletePipeline {
    export type RequestParams = {
      /** tenant is the tenant to delete a pipeline for. */
      tenant: string;
      /** id is the id of the pipeline to delete. */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1DeletePipelineResponse;
  } /**
 * No description
 * @tags PipelinesService
 * @name PipelinesServiceUpdatePipeline
 * @summary UpdatePipeline updates a given pipeline for the given tenant with the new
given parameters.
 * @request PUT:/v1alpha1/tenants/{tenant}/pipelines/{id}
 * @secure
 * @response `200` `V1Alpha1UpdatePipelineResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace PipelinesServiceUpdatePipeline {
    export type RequestParams = {
      /** tenant is the tenant to update a pipeline for. */
      tenant: string;
      /** id is the id of the pipeline to update. */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = {
      /** generate_request is the parameters for generating a pipeline. */
      generateRequest?: V1Alpha1GenerateRequest;
    };
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1UpdatePipelineResponse;
  }
  /**
   * No description
   * @tags PipelineRunsService
   * @name PipelineRunsServiceListPipelineRuns
   * @summary ListPipelineRuns returns a list of PipelineRuns for the given tenant.
   * @request GET:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns
   * @secure
   * @response `200` `V1Alpha1ListPipelineRunsResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace PipelineRunsServiceListPipelineRuns {
    export type RequestParams = {
      /** tenant is the tenant for the pipeline runs. */
      tenant: string;
      /** pipeline is the id of the pipeline within the tenant to list. */
      pipeline: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1ListPipelineRunsResponse;
  }
  /**
   * No description
   * @tags PipelineRunsService
   * @name PipelineRunsServiceCreatePipelineRun
   * @summary CreatePipelineRun runs a Pipeline for the given tenant with the given parameters.
   * @request POST:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns
   * @secure
   * @response `200` `V1Alpha1CreatePipelineRunResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace PipelineRunsServiceCreatePipelineRun {
    export type RequestParams = {
      /** tenant is the tenant for the pipeline run. */
      tenant: string;
      /** pipeline is the id of the pipeline within the tenant to run. */
      pipeline: string;
    };
    export type RequestQuery = {};
    export type RequestBody = {
      /**
       * parameter_overrides is a list of parameters to override within the
       * pipeline.
       */
      parameterOverrides?: V1Alpha1PipelineRunParameter[];
    };
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1CreatePipelineRunResponse;
  }
  /**
   * No description
   * @tags PipelineRunsService
   * @name PipelineRunsServiceGetPipelineRun
   * @summary GetPipelineRun returns the PipelineRun by its id for the given tenant.
   * @request GET:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns/{id}
   * @secure
   * @response `200` `V1Alpha1GetPipelineRunResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace PipelineRunsServiceGetPipelineRun {
    export type RequestParams = {
      /** tenant is the tenant for the pipeline run. */
      tenant: string;
      /** pipeline is the id of the pipeline within the tenant to get. */
      pipeline: string;
      /** id is the id of the pipeline run to get. */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1GetPipelineRunResponse;
  } /**
 * No description
 * @tags PipelineRunsService
 * @name PipelineRunsServiceDeletePipelineRun
 * @summary DeletePipelineRun deletes the given pipelinerun by its id for the given
tenant.
 * @request DELETE:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns/{id}
 * @secure
 * @response `200` `V1Alpha1DeletePipelineRunResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
*/
  export namespace PipelineRunsServiceDeletePipelineRun {
    export type RequestParams = {
      /** tenant is the tenant for the pipeline run. */
      tenant: string;
      /**
       * pipeline is the id of the pipeline within the tenant the pipeline run
       * belongs to.
       */
      pipeline: string;
      /** id is the id of the pipeline run to delete. */
      id: string;
    };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1DeletePipelineRunResponse;
  }
}

export namespace Webhook {
  /**
   * No description
   * @tags WebhookService
   * @name WebhookServiceGcpPubSubPush
   * @summary GCPPubSubPush is the endpoint for a GCP PubSub subscription to push to.
   * @request POST:/webhook/gcp-pubsub/v1alpha1
   * @secure
   * @response `200` `V1Alpha1GCPPubSubPushResponse` A successful response.
   * @response `default` `RpcStatus` An unexpected error response.
   */
  export namespace WebhookServiceGcpPubSubPush {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = V1Alpha1GCPPubSubPushRequest;
    export type RequestHeaders = {};
    export type ResponseBody = V1Alpha1GCPPubSubPushResponse;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Dracon Cloud REST API
 * @version v1alpha1
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  oauth2 = {
    /**
 * No description
 *
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceCallback
 * @summary Callback is where users get redirected to after they are authenticated
with the authorization server.
 * @request GET:/oauth2/callback
 * @response `200` `V1Alpha1CallbackResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    oAuth2ClientServiceCallback: (
      query?: {
        /** The server returns the authorization code in the query string. */
        code?: string;
        /** The server returns the same state value that you passed. */
        state?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V1Alpha1CallbackResponse, RpcStatus>({
        path: `/oauth2/callback`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceEndpoint
 * @summary Endpoint redirects/returns OAuth2 `/authorize` URL for clients to visit
in order to gain authentication.
 * @request GET:/oauth2/endpoint
 * @response `200` `V1Alpha1EndpointResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    oAuth2ClientServiceEndpoint: (
      query?: {
        /** provider_id is the OAuth2 provider id (a.k.a prefix) to use for SSO. */
        providerId?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<V1Alpha1EndpointResponse, RpcStatus>({
        path: `/oauth2/endpoint`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceProviders
 * @summary Providers returns a list of configured OAuth2 providers for clients to
present as authentication options.
 * @request GET:/oauth2/providers
 * @response `200` `V1Alpha1ProvidersResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    oAuth2ClientServiceProviders: (params: RequestParams = {}) =>
      this.request<V1Alpha1ProvidersResponse, RpcStatus>({
        path: `/oauth2/providers`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags OAuth2ClientService
 * @name OAuth2ClientServiceVerify
 * @summary Verify verifies the given access token from metadata, returning the
authenticated claims for the token.
 * @request GET:/oauth2/verify
 * @secure
 * @response `200` `V1Alpha1VerifyResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    oAuth2ClientServiceVerify: (params: RequestParams = {}) =>
      this.request<V1Alpha1VerifyResponse, RpcStatus>({
        path: `/oauth2/verify`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  v1Alpha1 = {
    /**
 * No description
 *
 * @tags PipelinesService
 * @name PipelinesServiceListCreatePipelineOptions
 * @summary ListCreatePipelineOptions returns a list of available Create Pipeline Options. For
example a list of available kustomize components.
 * @request GET:/v1alpha1/create-pipeline-options
 * @secure
 * @response `200` `V1Alpha1ListCreatePipelineOptionsResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    pipelinesServiceListCreatePipelineOptions: (params: RequestParams = {}) =>
      this.request<V1Alpha1ListCreatePipelineOptionsResponse, RpcStatus>({
        path: `/v1alpha1/create-pipeline-options`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantsService
     * @name TenantsServiceListTenants
     * @summary ListTenants returns a list of tenant that satisfies the given request.
     * @request GET:/v1alpha1/tenants
     * @secure
     * @response `200` `V1Alpha1ListTenantsResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    tenantsServiceListTenants: (params: RequestParams = {}) =>
      this.request<V1Alpha1ListTenantsResponse, RpcStatus>({
        path: `/v1alpha1/tenants`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags TenantsService
     * @name TenantsServiceGetTenant
     * @summary GetTenant returns a tenant for the given unique name.
     * @request GET:/v1alpha1/tenants/{name}
     * @secure
     * @response `200` `V1Alpha1GetTenantResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    tenantsServiceGetTenant: (name: string, params: RequestParams = {}) =>
      this.request<V1Alpha1GetTenantResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${name}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipelinesService
     * @name PipelinesServiceListPipelines
     * @summary ListPipelines returns a list of pipelines for the given tenant.
     * @request GET:/v1alpha1/tenants/{tenant}/pipelines
     * @secure
     * @response `200` `V1Alpha1ListPipelinesResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    pipelinesServiceListPipelines: (tenant: string, params: RequestParams = {}) =>
      this.request<V1Alpha1ListPipelinesResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags PipelinesService
 * @name PipelinesServiceCreatePipeline
 * @summary CreatePipeline creates a new pipeline with the given parameters for the
given tenant.
 * @request POST:/v1alpha1/tenants/{tenant}/pipelines
 * @secure
 * @response `200` `V1Alpha1CreatePipelineResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    pipelinesServiceCreatePipeline: (
      tenant: string,
      body: {
        /** name is the name of the pipeline to create. */
        name?: string;
        /** generator_configuration is the parameters for generating a pipeline. */
        generatorConfiguration?: V1Alpha1GeneratorConfiguration;
      },
      params: RequestParams = {},
    ) =>
      this.request<V1Alpha1CreatePipelineResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipelinesService
     * @name PipelinesServiceGetPipeline
     * @summary GetPipeline returns the given Pipeline by its tenant and id.
     * @request GET:/v1alpha1/tenants/{tenant}/pipelines/{id}
     * @secure
     * @response `200` `V1Alpha1GetPipelineResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    pipelinesServiceGetPipeline: (tenant: string, id: string, params: RequestParams = {}) =>
      this.request<V1Alpha1GetPipelineResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipelinesService
     * @name PipelinesServiceDeletePipeline
     * @summary DeletePipeline deletes a given pipeline for the given tenant.
     * @request DELETE:/v1alpha1/tenants/{tenant}/pipelines/{id}
     * @secure
     * @response `200` `V1Alpha1DeletePipelineResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    pipelinesServiceDeletePipeline: (tenant: string, id: string, params: RequestParams = {}) =>
      this.request<V1Alpha1DeletePipelineResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags PipelinesService
 * @name PipelinesServiceUpdatePipeline
 * @summary UpdatePipeline updates a given pipeline for the given tenant with the new
given parameters.
 * @request PUT:/v1alpha1/tenants/{tenant}/pipelines/{id}
 * @secure
 * @response `200` `V1Alpha1UpdatePipelineResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    pipelinesServiceUpdatePipeline: (
      tenant: string,
      id: string,
      body: {
        /** generate_request is the parameters for generating a pipeline. */
        generateRequest?: V1Alpha1GenerateRequest;
      },
      params: RequestParams = {},
    ) =>
      this.request<V1Alpha1UpdatePipelineResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines/${id}`,
        method: "PUT",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipelineRunsService
     * @name PipelineRunsServiceListPipelineRuns
     * @summary ListPipelineRuns returns a list of PipelineRuns for the given tenant.
     * @request GET:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns
     * @secure
     * @response `200` `V1Alpha1ListPipelineRunsResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    pipelineRunsServiceListPipelineRuns: (tenant: string, pipeline: string, params: RequestParams = {}) =>
      this.request<V1Alpha1ListPipelineRunsResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines/${pipeline}/pipelineruns`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipelineRunsService
     * @name PipelineRunsServiceCreatePipelineRun
     * @summary CreatePipelineRun runs a Pipeline for the given tenant with the given parameters.
     * @request POST:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns
     * @secure
     * @response `200` `V1Alpha1CreatePipelineRunResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    pipelineRunsServiceCreatePipelineRun: (
      tenant: string,
      pipeline: string,
      body: {
        /**
         * parameter_overrides is a list of parameters to override within the
         * pipeline.
         */
        parameterOverrides?: V1Alpha1PipelineRunParameter[];
      },
      params: RequestParams = {},
    ) =>
      this.request<V1Alpha1CreatePipelineRunResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines/${pipeline}/pipelineruns`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PipelineRunsService
     * @name PipelineRunsServiceGetPipelineRun
     * @summary GetPipelineRun returns the PipelineRun by its id for the given tenant.
     * @request GET:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns/{id}
     * @secure
     * @response `200` `V1Alpha1GetPipelineRunResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    pipelineRunsServiceGetPipelineRun: (tenant: string, pipeline: string, id: string, params: RequestParams = {}) =>
      this.request<V1Alpha1GetPipelineRunResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines/${pipeline}/pipelineruns/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
 * No description
 *
 * @tags PipelineRunsService
 * @name PipelineRunsServiceDeletePipelineRun
 * @summary DeletePipelineRun deletes the given pipelinerun by its id for the given
tenant.
 * @request DELETE:/v1alpha1/tenants/{tenant}/pipelines/{pipeline}/pipelineruns/{id}
 * @secure
 * @response `200` `V1Alpha1DeletePipelineRunResponse` A successful response.
 * @response `default` `RpcStatus` An unexpected error response.
 */
    pipelineRunsServiceDeletePipelineRun: (tenant: string, pipeline: string, id: string, params: RequestParams = {}) =>
      this.request<V1Alpha1DeletePipelineRunResponse, RpcStatus>({
        path: `/v1alpha1/tenants/${tenant}/pipelines/${pipeline}/pipelineruns/${id}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  webhook = {
    /**
     * No description
     *
     * @tags WebhookService
     * @name WebhookServiceGcpPubSubPush
     * @summary GCPPubSubPush is the endpoint for a GCP PubSub subscription to push to.
     * @request POST:/webhook/gcp-pubsub/v1alpha1
     * @secure
     * @response `200` `V1Alpha1GCPPubSubPushResponse` A successful response.
     * @response `default` `RpcStatus` An unexpected error response.
     */
    webhookServiceGcpPubSubPush: (body: V1Alpha1GCPPubSubPushRequest, params: RequestParams = {}) =>
      this.request<V1Alpha1GCPPubSubPushResponse, RpcStatus>({
        path: `/webhook/gcp-pubsub/v1alpha1`,
        method: "POST",
        body: body,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
