import { createContext, useState } from "react";
import { V1Alpha1Tenant, V1Alpha1TenantState } from "../api/api";
import { API } from "./APIContext";

export const TenantsContext = createContext<Tenants|undefined>(undefined);

export function NewTenants(api: API): Tenants {
  const [tenants, setTenants] = useState<TenantsByName>(<TenantsByName>{});
  const [currentTenant, setCurrentTenant] = useState<
    V1Alpha1Tenant | undefined
  >(undefined);

  return new Tenants(
    api,
    tenants,
    setTenants,
    currentTenant,
    setCurrentTenant
  );
}

interface TenantsByName {
  [index: string]: V1Alpha1Tenant;
}

export class Tenants {
  private api: API;

  private tenants: TenantsByName;
  private tenantsSetter: React.Dispatch<React.SetStateAction<TenantsByName>>;


  private currentTenant: V1Alpha1Tenant | undefined;
  private currentTenantSetter: React.Dispatch<
    React.SetStateAction<V1Alpha1Tenant | undefined>
  >;

  constructor(
    api: API,
    tenants: TenantsByName,
    tenantsSetter: React.Dispatch<React.SetStateAction<TenantsByName>>,
    currentTenant: V1Alpha1Tenant | undefined,
    currentTenantSetter: React.Dispatch<
      React.SetStateAction<V1Alpha1Tenant | undefined>
    >
  ) {
    this.api = api;
    this.tenants = tenants;
    this.tenantsSetter = tenantsSetter;
    this.currentTenant = currentTenant;
    this.currentTenantSetter = currentTenantSetter;
  }

  public getCurrentTenant(): V1Alpha1Tenant | undefined {
    return this.currentTenant
  }

  public getCurrentTenantName(): string {
    if (this.currentTenant === undefined) {
      return "";
    }

    return this.currentTenant.name as string;
  }

  public tenantFriendlyName(tenant: V1Alpha1Tenant | undefined): string {
    if (tenant === undefined) {
      return ""
    }

    const tenantName = tenant.name as string;
    const parts = tenantName.split(":");

    const vendor = parts[0];
    // const uid = parts[1];
    const name = parts[2];

    return `${vendor}/${name}`;
  }

  public setCurrentTenant(tenant: V1Alpha1Tenant | undefined) {
    this.currentTenantSetter(tenant);
  }

  public hasCurrentTenant(): boolean {
    return !this.currentTenant;
  }

  public getTenantByName(name: string): V1Alpha1Tenant | undefined {
    if (name in this.tenants) {
      return this.tenants[name];
    }

    return undefined;
  }

  public getTenants(): V1Alpha1Tenant[] {
    const tenants = Object.keys(this.tenants).map((key) => this.tenants[key]);
    return tenants;
  }

  public getInactiveTenants(): V1Alpha1Tenant[] {
    return this.getTenants().filter((tenant) => {
      return tenant.state === V1Alpha1TenantState.TENANT_STATE_INACTIVE;
    });
  }

  public getActiveTenants(): V1Alpha1Tenant[] {
    return this.getTenants().filter((tenant) => {
      return tenant.state === V1Alpha1TenantState.TENANT_STATE_ACTIVE;
    });
  }

  public setTenants(tenants: V1Alpha1Tenant[] | undefined) {
    const newTenantsByID = <TenantsByName>{};
    tenants?.forEach((element) => {
      newTenantsByID[element.name as string] = element;
    });

    this.tenantsSetter(newTenantsByID)
  }

  public async fetchTenants(): Promise<V1Alpha1Tenant[]|Response> {
    if (!this.api.hasAccessToken()) {
      return Promise.reject();
    }
    try {
      const resp = await this.api.client().v1Alpha1.tenantsServiceListTenants();
      if (resp.ok) {
        this.setTenants(resp.data.tenants);
        return resp.data.tenants as V1Alpha1Tenant[];
      }

      return Promise.reject(resp)
    } catch (err) {
      console.warn(err);

      return Promise.reject(err)
    }
  }
}
