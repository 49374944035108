import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";

import { UserContext } from "../contexts/UserContext";

function IndexPage() {
  const user = useContext(UserContext);

  let ctaButton = (
    <Button
        variant="contained"
        component={Link}
        href="https://www.ocurity.com/contact-us"
        target="_blank"
      >
        Request Demo Access
    </Button>
  )

  if (user?.isAuthenticated()) {
    ctaButton = (
      <Button
        variant="contained"
        component={RouterLink}
        to="/tenants"
      >
        Start Here!
    </Button>
    )
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 2,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Dracon Cloud
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            An automated security testing and reporting platform built for any
            code-base and infrastructure.
          </Typography>
          <Typography variant="body2" align="center" paragraph>
            Dracon helps you save time and better utilize your valuable team
            resources through aggregating, enriching and exporting findings from
            any security tool (SAST, DAST) to any data sink.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            {ctaButton}
          </Stack>
          <Box sx={{ bgcolor: "background.paper", mt: 12 }} component="footer">
            <Typography
              variant="body2"
              align="center"
              color="text.secondary"
              component="p"
            >
              Made with ❤️ by{" "}
              <Link target="_blank" href="https://ocurity.com">
                Ocurity Ltd.
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
export default IndexPage;
