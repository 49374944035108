import React from "react";
import Box from "@mui/material/Box";
import AdbIcon from "@mui/icons-material/Adb";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";

export default function Logo() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <AdbIcon sx={{ mr: 1 }} />
        <Typography
          variant="h5"
          noWrap
          component={RouterLink}
          to="/"
          sx={{
            flexGrow: 1,
            fontFamily: "monospace",
            fontWeight: 700,
            color: "inherit",
            textDecoration: "none",
          }}
        >
          Dracon
        </Typography>
      </Box>
    </>
  );
}
