import React, { useContext } from "react";
import { UserContext } from "../contexts/UserContext";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from '@mui/material/Link';
import Button from "@mui/material/Button";
import ColorModeToggle from "./ColorModeToggle";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";


export default
function Settings() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  if (!user?.isAuthenticated()) {
    return (
      <>
        <Button color="inherit" component={RouterLink} to="/login">
          Login
        </Button>
        <ColorModeToggle />
      </>
    );
  }

  return (
    <>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Avatar>
            <PersonIcon />
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {/* Username */}
        <MenuItem key="Username" disableRipple divider={true}>
          <Typography textAlign="left" variant="body2">
            Signed in as: <strong>{user?.getFriendlyUsername()}</strong>
          </Typography>
        </MenuItem>
        {/* OAuth2 settings */}
        <MenuItem
          key="OAuth2 settings"
          component={Link}
          href={user?.getOAuth2ReviewURL()}
          target="_blank"
        >
          <ListItemIcon>
            <AssuredWorkloadIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>OAuth2 settings</ListItemText>
        </MenuItem>
        {/* Dark Mode */}
        <ColorModeToggle menuItem={true} />
        {/* Logout */}
        <MenuItem
          key="Logout"
          onClick={() => {
            user?.logout();
            navigate("/");
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
