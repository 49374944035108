import React, { useContext } from "react";
import {UserContext} from "../contexts/UserContext"
import { Navigate } from 'react-router-dom';

interface Props {
  children: JSX.Element
}

export default function ProtectedRoute(props: Props) {
  const user = useContext(UserContext);

  if (!user?.isAuthenticated()) {
    return <Navigate to="/" replace />;
  }

  return props.children
}
