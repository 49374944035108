import React, { useContext } from "react";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";

import { ColorModeContext } from "../contexts/ColorModeContext";

interface ColorModeToggleProps {
  menuItem?: boolean;
}

export default function ColorModeToggle(props: ColorModeToggleProps) {
  const colorMode = useContext(ColorModeContext);
  const icon = (
    <>
      {colorMode?.get() === "dark" ? (
        <Brightness7Icon fontSize="small" />
      ) : (
        <Brightness4Icon fontSize="small" />
      )}
    </>
  );

  const toggleText =
  colorMode?.get() === "dark" ? "Light mode" : "Dark mode";

  if (props.menuItem) {
    return (
      <>
        <MenuItem
          divider={true}
          key="Dark Mode"
          onClick={() => {
            colorMode?.toggle();
          }}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>{toggleText}</ListItemText>
        </MenuItem>
      </>
    );
  }
  return (
    <>
      <IconButton
        sx={{ ml: 1 }}
        onClick={() => {
          colorMode?.toggle();
        }}
        color="inherit"
      >
        {icon}
      </IconButton>
    </>
  );
}
