import { createContext } from 'react';
import { Api } from "../api/api";

export const APIContext = createContext<API|undefined>(undefined);

type SecurityDataType = { token: string };

type Client = Api<SecurityDataType>;

type SuccessCallback = (response: Response) => void;
type ErrorCallback = (error: Response) => void;

export function NewAPI(): API {
  return new API()
}

export class API {
  private underlyingApi: Client;
  private isAccessTokenSet: boolean;

  private apiSuccessCallbacks: SuccessCallback[];
  private apiErrorCallbacks: ErrorCallback[];

  constructor() {
    this.underlyingApi = new Api({
      baseUrl: getAPIBaseURL(),
      securityWorker: (securityData: SecurityDataType | null) => {
        if (securityData) {
          return {
            headers: {
              ["Authorization"]: "Bearer " + securityData.token,
            },
          };
        }
      },
      customFetch: (input, init) =>
        fetch(input, init)
          .then((response) => {
            this.apiSuccessCallbacks.forEach((callbackFn: SuccessCallback) => {
              callbackFn(response);
            });

            if (response.ok) {
              return response;
            }

            return Promise.reject(response);
          })
          .catch((e) => {
            this.apiErrorCallbacks.forEach((callbackFn: ErrorCallback) => {
              callbackFn(e);
            });

            return Promise.reject(e);
          }),
    });
    this.isAccessTokenSet = false;

    this.apiSuccessCallbacks = [];
    this.apiErrorCallbacks = [];
  }

  public client(): Client {
    return this.underlyingApi;
  }

  public setAccessToken(token: string) {
    this.underlyingApi.setSecurityData({
      token: token,
    });
    this.isAccessTokenSet = true;
  }

  public hasAccessToken(): boolean {
    return this.isAccessTokenSet;
  }

  public addAPISuccessCallback(fn: SuccessCallback) {
    this.apiSuccessCallbacks.push(fn);
  }

  public addAPIErrorCallback(fn: ErrorCallback) {
    this.apiErrorCallbacks.push(fn);
  }
}

interface windowConfiguration {
  API_ENDPOINT: string
}

declare global {
  interface Window {
       configuration: windowConfiguration
  }
}

function getAPIBaseURL(): string {
  const c = window.configuration;
  if ('API_ENDPOINT' in c && c['API_ENDPOINT'].startsWith("http")) {
      return c['API_ENDPOINT'];
  }

  return "http://localhost:5000";
}
