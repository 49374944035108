import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { UserContext } from "../contexts/UserContext";

function OAuth2Callback() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [oAuth2Errors, setOAuth2Errors] = useState<string[]>([]);
  const [authenticated, setAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const code = searchParams.get("code") as string;
    const state = searchParams.get("state") as string;

    user?.doOAuth2Callback(code, state).catch((err:Response) => {
        setOAuth2Errors([err.statusText]);
    }).then(() => {
      setAuthenticated(true);
      navigate("/tenants", {replace: true});
    });
  }, []);

  if (authenticated) {
    return (<>
    <Alert severity="success">
      <AlertTitle>Successfully authenticated!</AlertTitle>
      Redirecting...
      </Alert>
    </>)
  }

  if (oAuth2Errors.length > 0) {
    return (<>
      <Alert severity="error">
      <AlertTitle>OAuth2 errors</AlertTitle>
        <ul>
          {oAuth2Errors.map((value) => (
            <li>{value}</li>
          ))}
        </ul>
    </Alert>
    </>)
  }

  return (<></>)
}

export default OAuth2Callback;
