import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { createRoot } from "react-dom/client";

import { SnackbarProvider } from "notistack";

import IndexPage from "./pages/Index";
import LoginPage from "./pages/LogIn";
import Base from "./layout/Base";
import OAuth2Callback from "./pages/OAuth2Callback";

import { ColorModeContext, NewColorMode } from "./contexts/ColorModeContext";
import Theme from "./layout/Theme";
import ProtectedRoute from "./layout/ProtectedRoute";

import { APIContext, NewAPI } from "./contexts/APIContext";
import { UserContext, NewUser } from "./contexts/UserContext";
import { TenantsContext, NewTenants } from "./contexts/TenantsContext";
import TenantsPage from "./pages/TenantsPage";
import TenantDashboard from "./pages/TenantDashboard";

export default function Root() {
  const api = NewAPI();
  const user = NewUser(api);
  const tenants = NewTenants(api);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Base />,
      // loader: rootLoader,
      children: [
        {
          path: "/",
          element: <IndexPage />,
        },
        {
          path: "/tenants",
          element: (
            <ProtectedRoute>
              <TenantsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "/tenants/:tenantId",
          element: (
            <ProtectedRoute>
              <TenantDashboard />
            </ProtectedRoute>
          ),
        },
        {
          path: "/login",
          element: <LoginPage />,
        },
        {
          path: "/oauth2/callback",
          element: <OAuth2Callback />,
        },
      ],
    },
  ]);

  return (
    <>
      <ColorModeContext.Provider value={NewColorMode()}>
        <Theme>
          <SnackbarProvider maxSnack={3} preventDuplicate>
            <APIContext.Provider value={api}>
              <UserContext.Provider value={user}>
                <TenantsContext.Provider value={tenants}>
                  <RouterProvider router={router} />
                </TenantsContext.Provider>
              </UserContext.Provider>
            </APIContext.Provider>
          </SnackbarProvider>
        </Theme>
      </ColorModeContext.Provider>
    </>
  );
}

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
