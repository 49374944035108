import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TenantsContext } from "../contexts/TenantsContext";
import { UserContext } from "../contexts/UserContext";

export default function TenantsPage() {

  const tenants = useContext(TenantsContext);
  const user = useContext(UserContext);

  return (
    <>
      <Box
        sx={{
          bgcolor: "background.paper",
          pt: 8,
          pb: 2,
        }}
      >
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
            Welcome!
          </Typography>
          <Typography
            variant="h5"
            align="center"
            color="text.secondary"
            paragraph
          >
            Please select a tenant.
          </Typography>
          <Typography variant="body2" align="center" paragraph>
            If all of your tenants are <em>(inactive)</em>, please reach out to
            us with a list of the tenants you would like to enable. Please use
            include the <em>Tenant ID</em>s from the list below in your request.
          </Typography>
          <TableContainer component={Paper}>

            <Table
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Inactive Tenants</TableCell>
                  <TableCell align="right">Tenant ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tenants?.getInactiveTenants().map((tenant) => (
                  <TableRow
                    key={tenant.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {tenants?.tenantFriendlyName(tenant)}
                    </TableCell>
                    <TableCell align="right"><pre>{tenant.name}</pre></TableCell>
                  </TableRow>
                ))}
              </TableBody>


            </Table>

          </TableContainer>
          <Typography variant="body2" align="center" paragraph sx={{mt: 3}}>
            <strong>Note</strong>: If a tenant appears to be missing from the table or drop down
            above, please review your OAuth2 settings <Link href={user?.getOAuth2ReviewURL()} target="_blank">here</Link> and
            re-login to grant yourself access to the missing tenants.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button
              variant="contained"
              component={Link}
              href="https://www.ocurity.com/contact-us"
              target="_blank"
            >
              Request Demo Access
            </Button>
          </Stack>
        </Container>
      </Box>
    </>
  );
}
