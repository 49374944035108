import { createContext, useState } from "react";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { V1Alpha1AccessTokenClaims } from "../api/api";
import { API } from "./APIContext";

export const UserContext = createContext<User|undefined>(undefined);

export function NewUser(api: API): User {
  const [accessToken, setAccessToken] = useLocalStorage(
    "dracon_cloud_access_token",
    ""
  );
  const [accessTokenClaims, setAccessTokenClaims] = useState<
    V1Alpha1AccessTokenClaims | undefined
  >(undefined);

  return new User(
    api,
    accessToken,
    setAccessToken,
    accessTokenClaims,
    setAccessTokenClaims,
  );
}

export class User {
 private api: API;
  private isApiAuthenticating: boolean;

  private accessToken: string;
  private accessTokenSetter: (
    value: string | ((val: string) => string)
  ) => void;

  private accessTokenClaims: V1Alpha1AccessTokenClaims | undefined;
  private accessTokenClaimsSetter: React.Dispatch<
    React.SetStateAction<V1Alpha1AccessTokenClaims | undefined>
  >;

  constructor(
    api: API,
    accessToken: string,
    accessTokenSetter: (value: string | ((val: string) => string)) => void,
    accessTokenClaims: V1Alpha1AccessTokenClaims | undefined,
    accessTokenClaimsSetter: React.Dispatch<
      React.SetStateAction<V1Alpha1AccessTokenClaims | undefined>
    >
  ) {
    this.api = api;
    this.isApiAuthenticating = false;

    this.accessToken = accessToken;
    this.accessTokenSetter = accessTokenSetter;
    this.accessTokenClaims = accessTokenClaims;
    this.accessTokenClaimsSetter = accessTokenClaimsSetter;

    if (this.accessToken !== "") {
      this.api.setAccessToken(this.accessToken);

      if (this.accessTokenClaims === undefined) {
        this.retrieveAccessTokenClaims();
      }
    }
  }

  public isAuthenticated(): boolean {
    return this.accessTokenClaims !== undefined;
  }

  public isAuthenticating(): boolean {
    return this.isApiAuthenticating;
  }

  public logout() {
    this.accessTokenSetter("");
    this.accessTokenClaimsSetter(undefined);
  }

  public getUsername(): string {
    if (this.accessTokenClaims === undefined) {
      return "";
    }
    return this.accessTokenClaims?.user?.username as string;
  }

  public getOAuth2ReviewURL(): string {
    return this.accessTokenClaims?.oAuth2ReviewUrl as string;
  }

  public getFriendlyUsername(): string {
    const parts = this.getUsername().split(":");
    if (parts.length != 3) {
      return parts.join(":");
    }

    const vendor = parts[0];
    // const uid = parts[1];
    const name = parts[2];

    return `${vendor}/${name}`;
  }

  public async oauth2Endpoints(): Promise<string[]> {
    try {
      const res = await this.api.client().oauth2.oAuth2ClientServiceProviders();

      return res.data.providerIds as string[];
    } catch (err) {
      console.warn(err);
      return Promise.reject(err);
    }
  }

  public async gotoOAuth2Endpoint(provider: string) {
    try {
      this.isApiAuthenticating = true;
      const res = await this.api.client().oauth2.oAuth2ClientServiceEndpoint({
        providerId: provider,
      });

      window.location.replace(res.data.endpoint as string);
    } catch (err) {
      console.warn(err);
    } finally {
      this.isApiAuthenticating = false;
    }
  }

  public async doOAuth2Callback(code: string, state: string) {
    if (this.isAuthenticated()) {
      return;
    }

    if (code === "" || state === "") {
      console.error("code and state parameters are required");
      return;
    }

    try {
      this.isApiAuthenticating = true;

      const res = await this.api.client().oauth2.oAuth2ClientServiceCallback({
        code: code,
        state: state,
      });

      if (res.ok) {
        this.accessTokenSetter(res.data.accessToken as string);
        this.api.setAccessToken(this.accessToken as string);

        return res;
      }

      return Promise.reject(res);
    } catch (err) {
      console.warn(err);

      return Promise.reject(err);
    } finally {
      this.isApiAuthenticating = false;
    }
  }

  public async retrieveAccessTokenClaims() {
    if (this.accessToken === "") {
      return;
    }

    try {
      this.isApiAuthenticating = true;
      const res = await this.api.client().oauth2.oAuth2ClientServiceVerify({});

      if (res.ok) {
        this.accessTokenClaimsSetter(res.data.claims);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      this.isApiAuthenticating = false;
    }
  }
}
