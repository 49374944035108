import React, { ReactElement, useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AppsIcon from '@mui/icons-material/Apps';
import GitHubIcon from "@mui/icons-material/GitHub";

import { UserContext } from "../contexts/UserContext";

interface OAuth2ProviderConfig {
  name: string
  icon?: ReactElement
  backgroundColor?: string
  color?: string
}

const OAuth2ProviderConfigs = new Map<string,OAuth2ProviderConfig>(
  [
    ["github", {
      name: "GitHub",
      icon: <GitHubIcon />,
      backgroundColor: "#333",
      color: "#FFF",
    }],
    ["local", {
      name: "local",
    }],
  ],
);

function LoginPage(): JSX.Element {
  const user = useContext(UserContext);

  const [providerConfigs, setProviderConfigs] = useState<Map<string,OAuth2ProviderConfig>>(new Map<string,OAuth2ProviderConfig>());

  useEffect(() => {
    user?.oauth2Endpoints().then((providers) => {
      const newProviderConfigs = new Map<string,OAuth2ProviderConfig>();
      providers.forEach((provider) => {
        const foundProviderConfig = OAuth2ProviderConfigs.get(provider)
        if (foundProviderConfig !== undefined) {
          newProviderConfigs.set(provider, foundProviderConfig)
        }
      });
      setProviderConfigs(newProviderConfigs);
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1 }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Container maxWidth="xs" sx={{ mt: 1 }}>
          <Grid container spacing={2}>
            {[...providerConfigs].sort().map(([provider, providerConfig]) => (
              <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: `${providerConfig.backgroundColor}`,
                  color: `${providerConfig.color}`,
                }}
                onClick={() => {
                  user?.gotoOAuth2Endpoint(provider);
                }}
              >
                {providerConfig.icon !== undefined ? (
                <>{providerConfig.icon}</>
                ) : (<AppsIcon />)}
                <Box sx={{ml: 1}}>Sign in with {providerConfig.name}</Box>
              </Button>
            </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </>
  );
}
export default LoginPage;
