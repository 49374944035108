import React, { useMemo, useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
import { ColorModeContext } from "../contexts/ColorModeContext";
import CssBaseline from "@mui/material/CssBaseline";

interface Props {
  children: React.ReactNode
}

function Theme(props: Props) {
  const colorMode = useContext(ColorModeContext);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: colorMode?.get() as PaletteMode,
        },
      }),
    [colorMode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}

export default Theme;
